<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <span class="text-white title-login">
        Sở Lao Động - Thương Binh và Xã Hội Tỉnh {{ $infoPortal().province }}
      </span>
      <span class="text-white title-login-small">
        Quản lý an toàn lao động
      </span>

      <!-- Brand logo-->
      <div @click="goToHome">
        <div class="brand-logo logo-home">
          <b-img
            class="w-100"
            :src="$infoPortal().sideImglogo"
          />
        </div>
        <!-- <vuexy-logo
          class="brand-logo logo-home "
        /> -->
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex theme-login w-100"
        :style="{background: `url(${sideImg})`}"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <!-- <b-img
            fluid
            :src="imageSuccess"
            alt="Login V2"
            class="cover-img"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-center mb-4">
            <b-img
              :src="statusActive.image"
              class="logo-success-active"
            />
          </div>

          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h3"
          >
            Kích hoạt tài khoản {{ statusActive.status }}
          </b-card-title>
          <b-card-text class="mb-2">

            {{ statusActive.title }}

          </b-card-text>

          <b-card-text class="text-center mt-2">
            <p class="text-center mt-2">
              <b-link :to="{name:'auth-login'}">
                <feather-icon icon="ChevronLeftIcon" /> Quay lại đăng nhập
              </b-link>
            </p>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardText, BCardTitle, BImg, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/IMG3.png'),
      roleUser: null,
      imageSuccess: require('@/assets/images/logo/message-checked.png'),
      imageDanger: require('@/assets/images/logo/message-danger.png'),
      statusActive: {
        title: 'Vui lòng đăng nhập tài khoản của bạn để sử dụng dịch vụ!',
        status: 'thành công',
        image: this.imageSuccess,
      },
      // validation rules
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/IMG3.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (this.$router.currentRoute.params.type === 'thanh-cong') {
      this.statusActive.title = 'Vui lòng đăng nhập tài khoản của bạn để sử dụng dịch vụ!'
      this.statusActive.status = 'thành công'
      this.statusActive.image = this.imageSuccess
    } else if (this.$router.currentRoute.params.type === 'kiem-tra-email') {
      this.statusActive.title = 'Vui lòng kiểm tra email kích hoạt tài khoản trước khi sử dụng!'
      this.statusActive.status = ''
      this.statusActive.image = this.imageSuccess
    } else {
      this.statusActive.title = 'Vui lòng kiểm tra lại email!'
      this.statusActive.status = 'thất bại'
      this.statusActive.image = this.imageDanger
    }
  },
  methods: {

    goToHome() {
      this.$router.push({ name: 'home', params: { check: true } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-success-active{

}
.logo-home {
  cursor: pointer;
}
.theme-login {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.title-login {
  position: absolute;
  z-index: 1;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  top: 3rem;
  left: 5rem;
  width: 40%;
}

.title-login-small {
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  top: 9.5rem;
  left: 5rem;
}

@media (max-width: 1300px) {
  .title-login {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .title-login {
    display: none;
  }
  .title-login-small {
    display: none;
  }
}
</style>
